const URLS = {
    'subscribe-fallback': (username: string) => `/${username}/referral/`,
    'subscribe': (username: string) => `/${username}`,
    'sitemap': (username: string) => `/${username}/sitemap.xml`,
    'premium-rss': (username: string, id: string) => `/${username}/subscribers/${id}/rss`,
    'rss': (username: string) => `/${username}/rss`,
    'comments-rss': (username: string) => `/${username}/comments/rss`,
    'legacy-rss': (username: string) => `/emails/rss/${username}`,
    'app': () => `/home`,
    'track-click': (compressed_id: string) => `/c/${compressed_id}/`,
    'track-open': (compressed_id: string) => `/o/${compressed_id}/`,
    'webmanifest': (username: string) => `/${username}/webmanifest.json`,
    'confirm-subscription-invalid-id': (username: string, subscriber_id: string) => `/${username}/confirm-subscription/${subscriber_id}`,
    'confirm-subscription': (username: string, subscriber_id: string) => `/${username}/confirm-subscription/${subscriber_id}`,
    'unsubscribe': (subscriber_id: string) => `/unsubscribe/${subscriber_id}`,
    'unsubscription-confirmation': (subscriber_id: string) => `/unsubscription-confirmation/${subscriber_id}/`,
    'unsubscription-success': (subscriber_id: string) => `/unsubscription-success/${subscriber_id}/`,
    'upgrade-subscription-success': (username: string, pk: string) => `/${username}/subscribers/${pk}/upgrade-success`,
    'subscription-success': (username: string, pk: string) => `/${username}/subscribers/${pk}/success`,
    'email-for-subscriber': (username: string, pk: string, slug: string) => `/${username}/subscribers/${pk}/archive/${slug}`,
    'archive-for-subscriber': (username: string, pk: string) => `/${username}/subscribers/${pk}/archive`,
    'purchase-paid-subscription': (username: string) => `/${username}/buy`,
    'survey-response-freeform': (username: string, subscriber_id: string, email_id: string, survey_id: string) => `/${username}/subscribers/${subscriber_id}/emails/${email_id}/surveys/${survey_id}`,
    'answer-survey': (username: string, subscriber_id: string, email_id: string, survey_id: string, answer: number) => `/${username}/subscribers/${subscriber_id}/emails/${email_id}/surveys/${survey_id}/answers/${answer}/record-survey-answer`,
    'upgrade-subscription': (username: string, pk: string) => `/${username}/subscribers/${pk}/upgrade/monthly`,
    'public-archive': (username: string) => `/${username}/archive/`,
    'email-via-id-with-no-slash': (username: string, pk: string) => `/${username}/archive/${pk}`,
    'email-via-id': (username: string, pk: string) => `/${username}/archive/${pk}/`,
    'raw-email-via-slug': (username: string, slug: string) => `/${username}/archive/${slug}.md`,
    'email': (username: string, slug: string) => `/${username}/archive/${slug}/`,
    'page': (username: string, slug: string) => `/${username}/pages/${slug}`,
    'email-via-substack-pattern': (username: string, slug: string) => `/${username}/p/${slug}`,
    'substack-migration-preview': (slug: string) => `/migration-preview/substack/${slug}`,
    'delete-comment': (username: string, email_slug: string, comment_id: string) => `/${username}/archive/${email_slug}/comment/${comment_id}/delete`,
    'comment': (username: string, email_slug: string, comment_id: string) => `/${username}/archive/${email_slug}/comment/${comment_id}`,
    'register-comment': (username: string, email_slug: string) => `/${username}/archive/${email_slug}/comment`,
    'raw-email': (username: string, pk: string) => `/${username}/archive/${pk}.md`,
    'notification-receipts-create': () => `/api/notifications/receipts/create`,
    'notifications-list': () => `/api/notifications/`,
    'register': () => `/api/emails/register`,
    'get-averaged-publish-time': () => `/api/emails/get_averaged_publish_time`,
    'get-subscriber-count': () => `/api/emails/get_subscriber_count`,
    'unsplash-download': () => `/api/emails/download_unsplash`,
    'unsplash-search': () => `/api/emails/search_unsplash`,
    'render-subscribe-template': () => `/api/emails/render_subscribe_template`,
    'send-test-custom-email': () => `/api/emails/send_test_custom_email`,
    'send-test-rss-email': () => `/api/emails/send_test_rss_email`,
    'retrieve-rss-items': () => `/api/emails/retrieve_rss_items`,
    'generate-alt-text': () => `/api/emails/generate_alt_text`,
    'generate-description': () => `/api/emails/generate_description`,
    'aggregate': (model: string) => `/api/emails/aggregate/${model}`,
    'check-for-mfa': () => `/api/emails/check-for-mfa`,
    'customer-portal': () => `/api/emails/customer-portal`,
    'upgrade-newsletter': () => `/api/emails/upgrade-newsletter`,
    'stripe-connect-callback': () => `/api/emails/stripe-connect-callback`,
    'check-urls': () => `/api/emails/check-urls`,
    'check-internal-links': () => `/api/emails/check-internal-links`,
    'resend-confirmation-email': () => `/api/emails/resend-confirmation-email`,
    'disconnect-stripe-account': () => `/api/emails/disconnect-stripe-account`,
    'send-test-email': () => `/api/emails/send-test-email`,
    'migrate-api-version': () => `/api/emails/migrate-api-version`,
    'regenerate-api-key': () => `/api/emails/regenerate-api-key`,
    'invoice': (invoice_id: string) => `/api/emails/invoice/${invoice_id}`,
    'pause-current-subscription': () => `/api/emails/pause-current-subscription`,
    'volume-based-billing-receipts': () => `/api/emails/volume-based-billing-receipts`,
    'capture-forwarded-draft': () => `/api/emails/capture-forwarded-draft`,
    'create-archive-import': () => `/api/emails/create-archive-import`,
    'create-subscriber-import': () => `/api/emails/create-subscriber-import`,
    'unsubscribe-from-lifecycle-emails': (newsletter_pk: string) => `/api/emails/unsubscribe-from-lifecycle-emails/${newsletter_pk}`,
    'canary': (subscriber_id: string, email_id: string) => `/api/emails/canary/${subscriber_id}/${email_id}/`,
    'check-user-email': () => `/api/emails/check-user-email`,
    'update-subscriber': (subscriber_id: string) => `/api/emails/update-subscriber/${subscriber_id}`,
    'create-subscriber': () => `/api/emails/create-subscriber`,
    'check-subscriber-email': () => `/api/emails/check-subscriber-email`,
    'check-username': () => `/api/emails/check-username`,
    'subscribe-from-embedded-form': (username: string) => `/api/emails/embed-subscribe/${username}`,
    'manage-premium-subscription': (subscriber_id: string) => `/api/emails/manage-premium-subscription/${subscriber_id}`,
    'confirm-invitation': (invitation_id: string) => `/api/emails/invitations/${invitation_id}`,
    'confirm-registration': (confirmation_id: string) => `/api/emails/confirm-registration/${confirmation_id}`,
    'oembed': () => `/embed`,
    'django': () => `/django`,
    'recent-updates-data': () => `/recent-updates-data`,
    'plain-webhook': () => `/plain/webhook/`,
    'postmark-webhook': () => `/mailgun_events/postmark`,
    'permanently-failed-webhook': () => `/mailgun_events/permanently-failed`,
    'failed-webhook': () => `/mailgun_events/failed`,
    'mailgun-v3-webhook': () => `/mailgun_events/mailgun-v3`,
    'naked-favicon': () => `/favicon.ico`,
    'password_reset_complete': () => `/accounts/password/reset_complete/`,
    'password_reset_confirm': (uidb64: string, token: string) => `/accounts/password/reset_confirm/${uidb64}-${token}/`,
    'password_reset_done': () => `/accounts/password/reset_done/`,
    'password_reset': () => `/accounts/password/reset/`,
    'password_change_done': () => `/accounts/password/done/`,
    'password_change': () => `/accounts/password/change/`,
    'register-webmention': () => `/webmentions/register`,
    'render': () => `/markdown/render`,
    'finish-email-confirmation': (email_confirmation_id: string) => `/email_address_validation/finish-email-confirmation/${email_confirmation_id}`,
    'start-email-confirmation': () => `/email_address_validation/start-email-confirmation`,
    'socialaccount_login_cancelled': () => `/accounts/login/`,
    'linkedin_login': (provider_id: string) => `/accounts/${provider_id}/login/`,
    'openid_connect_callback': (provider_id: string) => `/accounts/${provider_id}/login/callback`,
    'apple_finish_callback': () => `/accounts/apple/login/finish_callback/`,
    'apple_callback': () => `/accounts/apple/login/callback/`,
    'apple_login': () => `/accounts/apple/login/`,
    'discord_webhook_callback': () => `/accounts/discord_webhook/login/callback/`,
    'discord_webhook_login': () => `/accounts/discord_webhook/login/`,
    'twitter_oauth2_callback': () => `/accounts/twitter_oauth2/login/callback/`,
    'twitter_oauth2_login': () => `/accounts/twitter_oauth2/login/`,
    'google_callback': () => `/accounts/google/login/callback/`,
    'google_login': () => `/accounts/google/login/`,
    'tumblr_callback': () => `/accounts/tumblr/login/callback/`,
    'tumblr_login': () => `/accounts/tumblr/login/`,
    'socialaccount_connections': () => `/accounts/connections/`,
    'account_login': () => `/accounts/login/`,
    'auth_logout': () => `/api/auth/logout/`,
    'auth_login': () => `/api/auth/login/`,
    'oauth_token': () => `/oauth/token`,
    'oauth_authorize': () => `/oauth/authorize`,
    'ip-addresses': (ip: string) => `/ip-addresses/${ip}/`,
    'ajax_metrics': (metric_group_name: string, metric_name: string) => `/metrics/ajax/${metric_group_name}/${metric_name}/`,
    'metrics': () => `/metrics/`,
    'internal-api:api-root': () => `/api/emails/api/`,
    'internal-api:get_happenings': () => `/api/emails/api/happenings`,
    'internal-api:get_todos': () => `/api/emails/api/todos`,
    'internal-api:list_stripe_subscriptions': () => `/api/emails/api/stripe/subscriptions`,
    'internal-api:list_memberful_subscriptions': () => `/api/emails/api/memberful/subscriptions`,
    'internal-api:validations': (email_id: string) => `/api/emails/api/validators/${email_id}`,
    'internal-api:list_memberful_plans': () => `/api/emails/api/memberful/plans`,
    'internal-api:list_referrer_urls': () => `/api/emails/api/referrer-urls`,
    'internal-api:update_unsubscription_reason': (id: string) => `/api/emails/api/unsubscription-reasons/${id}`,
    'internal-api:list_unsubscription_reasons': () => `/api/emails/api/unsubscription-reasons`,
    'internal-api:connect_authentication_token': (id: string) => `/api/emails/api/authentication_tokens/${id}/connect`,
    'internal-api:delete_authentication_token': (id: string) => `/api/emails/api/authentication_tokens/${id}`,
    'internal-api:list_authentication_tokens': () => `/api/emails/api/authentication_tokens/`,
    'internal-api:update_account': (id: string) => `/api/emails/api/account/${id}`,
    'internal-api:retrieve_messages': (conversation_id: string) => `/api/emails/api/conversations/${conversation_id}/messages`,
    'internal-api:update_conversation': (conversation_id: string) => `/api/emails/api/conversations/${conversation_id}`,
    'internal-api:retrieve_conversations': () => `/api/emails/api/conversations`,
    'internal-api:update_newsletter': (id: string) => `/api/emails/api/newsletters/${id}`,
    'internal-api:list_attachments': () => `/api/emails/api/attachments`,
    'internal-api:list_utm_campaigns': () => `/api/emails/api/utm-campaigns`,
    'internal-api:list_utm_mediums': () => `/api/emails/api/utm-mediums`,
    'internal-api:list_utm_sources': () => `/api/emails/api/utm-sources`,
    'internal-api:update_metadata_field': (id: string) => `/api/emails/api/metadata_fields/${id}`,
    'internal-api:list_metadata_fields': () => `/api/emails/api/metadata_fields`,
    'internal-api:delete_draft_recipient': (id: string) => `/api/emails/api/draft-recipients/${id}`,
    'internal-api:list_draft_recipients': () => `/api/emails/api/draft-recipients`,
    'internal-api:create_search': () => `/api/emails/api/search`,
    'internal-api:retrieve_mentions': () => `/api/emails/api/mentions`,
    'internal-api:retrieve_analytics': (metric: string) => `/api/emails/api/analytics/${metric}`,
    'internal-api:list_referrals': () => `/api/emails/api/referrals`,
    'internal-api:list_undeliverability_reasons': () => `/api/emails/api/undeliverability-reasons`,
    'internal-api:list_products': () => `/api/emails/api/products`,
    'internal-api:list_invoices': () => `/api/emails/api/invoices`,
    'internal-api:get_sending_domain': (domain: string) => `/api/emails/api/domains/sending/${domain}`,
    'internal-api:get_hosting_domain': (domain: string) => `/api/emails/api/domains/hosting/${domain}`,
    'internal-api:list_archive_imports': () => `/api/emails/api/archive-imports`,
    'internal-api:get_subscriber_rates': () => `/api/emails/api/subscriber-rates`,
    'internal-api:list_subscriptions': () => `/api/emails/api/subscriptions`,
    'internal-api:update_subscriber_import': (id: string) => `/api/emails/api/subscriber-imports/${id}`,
    'internal-api:get_subscriber_import_text': (id: string) => `/api/emails/api/subscriber-imports/${id}/text`,
    'internal-api:list_subscriber_imports': () => `/api/emails/api/subscriber-imports`,
    'internal-api:replay_external_event': (id: string) => `/api/emails/api/events/${id}/replay`,
    'internal-api:list_external_events': () => `/api/emails/api/events`,
    'internal-api:openapi-view': () => `/api/emails/api/docs`,
    'internal-api:openapi-json': () => `/api/emails/api/openapi.json`,
    'api:api-root': () => `/v1/`,
    'api:get_event': (id: string) => `/v1/events/${id}`,
    'api:list_events': () => `/v1/events`,
    'api:retrieve_survey_responses': () => `/v1/survey_responses`,
    'api:list_comments': () => `/v1/comments`,
    'api:test_webhook': (id: string) => `/v1/webhooks/${id}/test`,
    'api:retrieve_webhook_attempts': (id: string) => `/v1/webhooks/${id}/attempts`,
    'api:retrieve_webhook': (id: string) => `/v1/webhooks/${id}`,
    'api:create_webhook': () => `/v1/webhooks`,
    'api:update_advertising_unit': (id: string) => `/v1/advertising_units/${id}`,
    'api:list_advertising_units': () => `/v1/advertising_units`,
    'api:list_api_requests': () => `/v1/api_requests`,
    'api:retrieve_api_request': (id: string) => `/v1/api_requests/${id}`,
    'api:retrieve_survey': (id: string) => `/v1/surveys/${id}`,
    'api:create_survey': () => `/v1/surveys`,
    'api:list_coupons': () => `/v1/coupons`,
    'api:list_prices': () => `/v1/prices`,
    'api:delete_user': (id: string) => `/v1/users/${id}`,
    'api:create_user': () => `/v1/users`,
    'api:retrieve_automation_analytics': (id: string) => `/v1/automations/${id}/analytics`,
    'api:invoke_automation': (id: string) => `/v1/automations/${id}/invoke`,
    'api:retrieve_automation': (id: string) => `/v1/automations/${id}`,
    'api:retrieve_automation_subscribers': (id: string) => `/v1/automations/${id}/subscribers`,
    'api:list_automations': () => `/v1/automations`,
    'api:poll_items': (id: string) => `/v1/external_feeds/${id}/items`,
    'api:update_external_feed': (id: string) => `/v1/external_feeds/${id}`,
    'api:create_external_feed': () => `/v1/external_feeds`,
    'api:retrieve_bulk_action': (id: string) => `/v1/bulk_actions/${id}`,
    'api:create_bulk_action': () => `/v1/bulk_actions`,
    'api:update_newsletter': (id: string) => `/v1/newsletters/${id}`,
    'api:list_newsletters': () => `/v1/newsletters`,
    'api:get_stripe_subscriptions': (id_or_email: string) => `/v1/subscribers/${id_or_email}/stripe-subscriptions`,
    'api:get_automations': (id_or_email: string) => `/v1/subscribers/${id_or_email}/automations`,
    'api:get_referrals': (id_or_email: string) => `/v1/subscribers/${id_or_email}/referrals`,
    'api:send_email_to': (id_or_email: string, email_id: string) => `/v1/subscribers/${id_or_email}/emails/${email_id}`,
    'api:send_reminder': (id_or_email: string) => `/v1/subscribers/${id_or_email}/send-reminder`,
    'api:retrieve_subscriber': (id_or_email: string) => `/v1/subscribers/${id_or_email}`,
    'api:create_subscriber': () => `/v1/subscribers`,
    'api:send_draft': (id: string) => `/v1/emails/${id}/send-draft`,
    'api:retrieve_email_analytics': (id: string) => `/v1/emails/${id}/analytics`,
    'api:retrieve_email_history_by_id': (id: string, history_id: string) => `/v1/emails/${id}/history/body/${history_id}`,
    'api:retrieve_email_history': (id: string) => `/v1/emails/${id}/history`,
    'api:update_email': (id: string) => `/v1/emails/${id}`,
    'api:create_email': () => `/v1/emails`,
    'api:delete_image': (id: string) => `/v1/images/${id}`,
    'api:create_image': () => `/v1/images`,
    'api:get_account': () => `/v1/accounts/me`,
    'api:ping': () => `/v1/ping`,
    'api:retrieve_tag_analytics': (id: string) => `/v1/tags/${id}/analytics`,
    'api:retrieve_tag': (id: string) => `/v1/tags/${id}`,
    'api:create_tag': () => `/v1/tags`,
    'api:retrieve_export': (id: string) => `/v1/exports/${id}`,
    'api:create_export': () => `/v1/exports`,
    'api:openapi-view': () => `/v1/docs`,
    'api:openapi-json': () => `/v1/openapi.json`,
    'github:webhook': () => `/github/webhook/`,
};
export default URLS;
